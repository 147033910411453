import { default as HomeZKrNw7JYE6Meta } from "~/pages/Home/Home.vue?macro=true";
import { default as GalleryojE48DYWCTMeta } from "~/pages/Gallery.vue?macro=true";
import { default as TermsOfUseggpkidQATvMeta } from "~/pages/TermsOfUse/TermsOfUse.vue?macro=true";
import { default as PrivacyPolicyJEzRmLSxHZMeta } from "~/pages/PrivacyPolicy/PrivacyPolicy.vue?macro=true";
import { default as SustainabilityycSM494xqhMeta } from "~/pages/LandingPages/Sustainability.vue?macro=true";
import { default as UnityLeaguekdT45MuJ2ZMeta } from "~/pages/LandingPages/marketing/UnityLeague.vue?macro=true";
import { default as SignInZ96HATdaIUMeta } from "~/pages/Account/SignIn.vue?macro=true";
import { default as SignOut8x3xge3In2Meta } from "~/pages/Account/SignOut.vue?macro=true";
import { default as ConfirmEmailkynUvu70TTMeta } from "~/pages/Account/ConfirmEmail.vue?macro=true";
import { default as ResetPasswordxZdTxsef38Meta } from "~/pages/Account/ResetPassword/ResetPassword.vue?macro=true";
import { default as ResetPasswordConfirmvY14NehynwMeta } from "~/pages/Account/ResetPassword/ResetPasswordConfirm.vue?macro=true";
import { default as DashboardjhiBkbel5ZMeta } from "~/pages/Dashboard/Dashboard.vue?macro=true";
import { default as Subscribek1UBpgARYGMeta } from "~/pages/Dashboard/Subscription/Subscribe.vue?macro=true";
import { default as ShippingAddressdiCeOxbFk3Meta } from "~/pages/Dashboard/ShippingAddress/ShippingAddress.vue?macro=true";
import { default as Profile8FEKkMORkdMeta } from "~/pages/Dashboard/Profile/Profile.vue?macro=true";
import { default as PaymentProfileohsik3qy58Meta } from "~/pages/Dashboard/PaymentProfile/PaymentProfile.vue?macro=true";
import { default as OrderListYh66REM5iBMeta } from "~/pages/Dashboard/Orders/OrderList.vue?macro=true";
import { default as OrderUn0K3CGa83Meta } from "~/pages/Dashboard/Orders/Order.vue?macro=true";
import { default as RewardListr1v62FkpQeMeta } from "~/pages/Dashboard/Rewards/RewardList.vue?macro=true";
import { default as SubscriptionBoxOrderZt6TRa5UrFMeta } from "~/pages/Dashboard/Subscription/SubscriptionBoxOrders/Details/SubscriptionBoxOrder.vue?macro=true";
import { default as FeedbackLS8z4esljmMeta } from "~/pages/Dashboard/Subscription/SubscriptionBoxOrders/Details/Feedback/Feedback.vue?macro=true";
import { default as Trackingflt5P6zoyBMeta } from "~/pages/Dashboard/Subscription/SubscriptionBoxOrders/Tracking/Tracking.vue?macro=true";
import { default as MigrateSubscriptionRIoeAautezMeta } from "~/pages/Dashboard/Subscription/MigrateSubscription.vue?macro=true";
import { default as CancelSubscriptionRenewal5IRjqV2wB0Meta } from "~/pages/Dashboard/Subscription/CancelSubscriptionRenewal.vue?macro=true";
import { default as DownloadBankSlipxBksLsznEFMeta } from "~/pages/Dashboard/BankSlip/DownloadBankSlip.vue?macro=true";
import { default as AffiliateDashboardqd2hwHg4U8Meta } from "~/pages/Dashboard/Marketing/AffiliateDashboard.vue?macro=true";
import { default as ContractOEI0IfFPRZMeta } from "~/pages/Subscription/Contract.vue?macro=true";
import { default as RegisterAEew29Fc5ZMeta } from "~/pages/Subscription/Register.vue?macro=true";
export default [
  {
    name: HomeZKrNw7JYE6Meta?.name ?? "home",
    path: HomeZKrNw7JYE6Meta?.path ?? "/",
    meta: HomeZKrNw7JYE6Meta || {},
    alias: HomeZKrNw7JYE6Meta?.alias || [],
    redirect: HomeZKrNw7JYE6Meta?.redirect || undefined,
    component: () => import("~/pages/Home/Home.vue").then(m => m.default || m)
  },
  {
    name: GalleryojE48DYWCTMeta?.name ?? "gallery",
    path: GalleryojE48DYWCTMeta?.path ?? "/gallery",
    meta: GalleryojE48DYWCTMeta || {},
    alias: GalleryojE48DYWCTMeta?.alias || [],
    redirect: GalleryojE48DYWCTMeta?.redirect || undefined,
    component: () => import("~/pages/Gallery.vue").then(m => m.default || m)
  },
  {
    name: TermsOfUseggpkidQATvMeta?.name ?? "terms-of-use",
    path: TermsOfUseggpkidQATvMeta?.path ?? "/termos-de-uso",
    meta: TermsOfUseggpkidQATvMeta || {},
    alias: TermsOfUseggpkidQATvMeta?.alias || [],
    redirect: TermsOfUseggpkidQATvMeta?.redirect || undefined,
    component: () => import("~/pages/TermsOfUse/TermsOfUse.vue").then(m => m.default || m)
  },
  {
    name: PrivacyPolicyJEzRmLSxHZMeta?.name ?? "privacy-policy",
    path: PrivacyPolicyJEzRmLSxHZMeta?.path ?? "/politica-de-privacidade",
    meta: PrivacyPolicyJEzRmLSxHZMeta || {},
    alias: PrivacyPolicyJEzRmLSxHZMeta?.alias || [],
    redirect: PrivacyPolicyJEzRmLSxHZMeta?.redirect || undefined,
    component: () => import("~/pages/PrivacyPolicy/PrivacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: SustainabilityycSM494xqhMeta?.name ?? "sustainability",
    path: SustainabilityycSM494xqhMeta?.path ?? "/sustentabilidade",
    meta: SustainabilityycSM494xqhMeta || {},
    alias: SustainabilityycSM494xqhMeta?.alias || [],
    redirect: SustainabilityycSM494xqhMeta?.redirect || undefined,
    component: () => import("~/pages/LandingPages/Sustainability.vue").then(m => m.default || m)
  },
  {
    name: UnityLeaguekdT45MuJ2ZMeta?.name ?? "unity-league",
    path: UnityLeaguekdT45MuJ2ZMeta?.path ?? "/lp/evento-unity",
    meta: UnityLeaguekdT45MuJ2ZMeta || {},
    alias: UnityLeaguekdT45MuJ2ZMeta?.alias || [],
    redirect: UnityLeaguekdT45MuJ2ZMeta?.redirect || undefined,
    component: () => import("~/pages/LandingPages/marketing/UnityLeague.vue").then(m => m.default || m)
  },
  {
    name: SignInZ96HATdaIUMeta?.name ?? "sign-in",
    path: SignInZ96HATdaIUMeta?.path ?? "/minha-conta/entrar",
    meta: SignInZ96HATdaIUMeta || {},
    alias: SignInZ96HATdaIUMeta?.alias || [],
    redirect: SignInZ96HATdaIUMeta?.redirect || undefined,
    component: () => import("~/pages/Account/SignIn.vue").then(m => m.default || m)
  },
  {
    name: SignOut8x3xge3In2Meta?.name ?? "sign-out",
    path: SignOut8x3xge3In2Meta?.path ?? "/minha-conta/sair",
    meta: SignOut8x3xge3In2Meta || {},
    alias: SignOut8x3xge3In2Meta?.alias || [],
    redirect: SignOut8x3xge3In2Meta?.redirect || undefined,
    component: () => import("~/pages/Account/SignOut.vue").then(m => m.default || m)
  },
  {
    name: ConfirmEmailkynUvu70TTMeta?.name ?? "confirm-email",
    path: ConfirmEmailkynUvu70TTMeta?.path ?? "/minha-conta/confirmar-email/:ticket",
    meta: ConfirmEmailkynUvu70TTMeta || {},
    alias: ConfirmEmailkynUvu70TTMeta?.alias || [],
    redirect: ConfirmEmailkynUvu70TTMeta?.redirect || undefined,
    component: () => import("~/pages/Account/ConfirmEmail.vue").then(m => m.default || m)
  },
  {
    name: ResetPasswordxZdTxsef38Meta?.name ?? "reset-password",
    path: ResetPasswordxZdTxsef38Meta?.path ?? "/minha-conta/recuperar-senha",
    meta: ResetPasswordxZdTxsef38Meta || {},
    alias: ResetPasswordxZdTxsef38Meta?.alias || [],
    redirect: ResetPasswordxZdTxsef38Meta?.redirect || undefined,
    component: () => import("~/pages/Account/ResetPassword/ResetPassword.vue").then(m => m.default || m)
  },
  {
    name: ResetPasswordConfirmvY14NehynwMeta?.name ?? "reset-password-confirm",
    path: ResetPasswordConfirmvY14NehynwMeta?.path ?? "/minha-conta/redefinir-senha/:ticket",
    meta: ResetPasswordConfirmvY14NehynwMeta || {},
    alias: ResetPasswordConfirmvY14NehynwMeta?.alias || [],
    redirect: ResetPasswordConfirmvY14NehynwMeta?.redirect || undefined,
    component: () => import("~/pages/Account/ResetPassword/ResetPasswordConfirm.vue").then(m => m.default || m)
  },
  {
    name: DashboardjhiBkbel5ZMeta?.name ?? "dashboard",
    path: DashboardjhiBkbel5ZMeta?.path ?? "/minha-conta",
    meta: DashboardjhiBkbel5ZMeta || {},
    alias: DashboardjhiBkbel5ZMeta?.alias || [],
    redirect: DashboardjhiBkbel5ZMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Dashboard.vue").then(m => m.default || m)
  },
  {
    name: Subscribek1UBpgARYGMeta?.name ?? "subscribe",
    path: Subscribek1UBpgARYGMeta?.path ?? "/minha-conta/assinar",
    meta: Subscribek1UBpgARYGMeta || {},
    alias: Subscribek1UBpgARYGMeta?.alias || [],
    redirect: Subscribek1UBpgARYGMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Subscription/Subscribe.vue").then(m => m.default || m)
  },
  {
    name: ShippingAddressdiCeOxbFk3Meta?.name ?? "edit-shipping-address",
    path: ShippingAddressdiCeOxbFk3Meta?.path ?? "/minha-conta/endereco-de-entrega",
    meta: ShippingAddressdiCeOxbFk3Meta || {},
    alias: ShippingAddressdiCeOxbFk3Meta?.alias || [],
    redirect: ShippingAddressdiCeOxbFk3Meta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/ShippingAddress/ShippingAddress.vue").then(m => m.default || m)
  },
  {
    name: Profile8FEKkMORkdMeta?.name ?? "edit-profile",
    path: Profile8FEKkMORkdMeta?.path ?? "/minha-conta/perfil-de-beleza/:step?",
    meta: Profile8FEKkMORkdMeta || {},
    alias: Profile8FEKkMORkdMeta?.alias || [],
    redirect: Profile8FEKkMORkdMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Profile/Profile.vue").then(m => m.default || m)
  },
  {
    name: PaymentProfileohsik3qy58Meta?.name ?? "edit-payment-profile",
    path: PaymentProfileohsik3qy58Meta?.path ?? "/minha-conta/dados-de-pagamento",
    meta: PaymentProfileohsik3qy58Meta || {},
    alias: PaymentProfileohsik3qy58Meta?.alias || [],
    redirect: PaymentProfileohsik3qy58Meta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/PaymentProfile/PaymentProfile.vue").then(m => m.default || m)
  },
  {
    name: OrderListYh66REM5iBMeta?.name ?? "order-list",
    path: OrderListYh66REM5iBMeta?.path ?? "/minha-conta/compras",
    meta: OrderListYh66REM5iBMeta || {},
    alias: OrderListYh66REM5iBMeta?.alias || [],
    redirect: OrderListYh66REM5iBMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Orders/OrderList.vue").then(m => m.default || m)
  },
  {
    name: OrderUn0K3CGa83Meta?.name ?? "order",
    path: OrderUn0K3CGa83Meta?.path ?? "/minha-conta/compras/:orderId",
    meta: OrderUn0K3CGa83Meta || {},
    alias: OrderUn0K3CGa83Meta?.alias || [],
    redirect: OrderUn0K3CGa83Meta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Orders/Order.vue").then(m => m.default || m)
  },
  {
    name: RewardListr1v62FkpQeMeta?.name ?? "reward-list",
    path: RewardListr1v62FkpQeMeta?.path ?? "/minha-conta/resgatar-premios",
    meta: RewardListr1v62FkpQeMeta || {},
    alias: RewardListr1v62FkpQeMeta?.alias || [],
    redirect: RewardListr1v62FkpQeMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Rewards/RewardList.vue").then(m => m.default || m)
  },
  {
    name: SubscriptionBoxOrderZt6TRa5UrFMeta?.name ?? "subscription-box-order",
    path: SubscriptionBoxOrderZt6TRa5UrFMeta?.path ?? "/minha-conta/box/:orderId",
    meta: SubscriptionBoxOrderZt6TRa5UrFMeta || {},
    alias: SubscriptionBoxOrderZt6TRa5UrFMeta?.alias || [],
    redirect: SubscriptionBoxOrderZt6TRa5UrFMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Subscription/SubscriptionBoxOrders/Details/SubscriptionBoxOrder.vue").then(m => m.default || m)
  },
  {
    name: FeedbackLS8z4esljmMeta?.name ?? "subscription-box-order-feedback",
    path: FeedbackLS8z4esljmMeta?.path ?? "/minha-conta/box/:orderId/feedback",
    meta: FeedbackLS8z4esljmMeta || {},
    alias: FeedbackLS8z4esljmMeta?.alias || [],
    redirect: FeedbackLS8z4esljmMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Subscription/SubscriptionBoxOrders/Details/Feedback/Feedback.vue").then(m => m.default || m)
  },
  {
    name: Trackingflt5P6zoyBMeta?.name ?? "subscription-box-order-tracking",
    path: Trackingflt5P6zoyBMeta?.path ?? "/minha-conta/rastreamento/:orderId",
    meta: Trackingflt5P6zoyBMeta || {},
    alias: Trackingflt5P6zoyBMeta?.alias || [],
    redirect: Trackingflt5P6zoyBMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Subscription/SubscriptionBoxOrders/Tracking/Tracking.vue").then(m => m.default || m)
  },
  {
    name: MigrateSubscriptionRIoeAautezMeta?.name ?? "migrate-subscription",
    path: MigrateSubscriptionRIoeAautezMeta?.path ?? "/minha-conta/migrar-assinatura",
    meta: MigrateSubscriptionRIoeAautezMeta || {},
    alias: MigrateSubscriptionRIoeAautezMeta?.alias || [],
    redirect: MigrateSubscriptionRIoeAautezMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Subscription/MigrateSubscription.vue").then(m => m.default || m)
  },
  {
    name: CancelSubscriptionRenewal5IRjqV2wB0Meta?.name ?? "cancel-subscription-renewal",
    path: CancelSubscriptionRenewal5IRjqV2wB0Meta?.path ?? "/minha-conta/cancelar-renovacao-de-assinatura",
    meta: CancelSubscriptionRenewal5IRjqV2wB0Meta || {},
    alias: CancelSubscriptionRenewal5IRjqV2wB0Meta?.alias || [],
    redirect: CancelSubscriptionRenewal5IRjqV2wB0Meta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Subscription/CancelSubscriptionRenewal.vue").then(m => m.default || m)
  },
  {
    name: DownloadBankSlipxBksLsznEFMeta?.name ?? "download-bank-slip",
    path: DownloadBankSlipxBksLsznEFMeta?.path ?? "/boleto-bancario",
    meta: DownloadBankSlipxBksLsznEFMeta || {},
    alias: DownloadBankSlipxBksLsznEFMeta?.alias || [],
    redirect: DownloadBankSlipxBksLsznEFMeta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/BankSlip/DownloadBankSlip.vue").then(m => m.default || m)
  },
  {
    name: AffiliateDashboardqd2hwHg4U8Meta?.name ?? "affiliate-dashboard",
    path: AffiliateDashboardqd2hwHg4U8Meta?.path ?? "/minha-conta/dashboard-de-afiliado",
    meta: AffiliateDashboardqd2hwHg4U8Meta || {},
    alias: AffiliateDashboardqd2hwHg4U8Meta?.alias || [],
    redirect: AffiliateDashboardqd2hwHg4U8Meta?.redirect || undefined,
    component: () => import("~/pages/Dashboard/Marketing/AffiliateDashboard.vue").then(m => m.default || m)
  },
  {
    name: ContractOEI0IfFPRZMeta?.name ?? "subscription-contract",
    path: ContractOEI0IfFPRZMeta?.path ?? "/contrato-de-assinatura",
    meta: ContractOEI0IfFPRZMeta || {},
    alias: ContractOEI0IfFPRZMeta?.alias || [],
    redirect: ContractOEI0IfFPRZMeta?.redirect || undefined,
    component: () => import("~/pages/Subscription/Contract.vue").then(m => m.default || m)
  },
  {
    name: RegisterAEew29Fc5ZMeta?.name ?? "subscription-register",
    path: RegisterAEew29Fc5ZMeta?.path ?? "/assinar/:step?/:currentQuestion?",
    meta: RegisterAEew29Fc5ZMeta || {},
    alias: RegisterAEew29Fc5ZMeta?.alias || [],
    redirect: RegisterAEew29Fc5ZMeta?.redirect || undefined,
    component: () => import("~/pages/Subscription/Register.vue").then(m => m.default || m)
  }
]